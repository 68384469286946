<template>
    <div>
        <l-header></l-header>
        <img-box img="banner3.png"></img-box>
        <img-box img="driver_bz.png"></img-box>
        <l-footer></l-footer>
    </div>
</template>

<script>
import LHeader from "@/components/Header";
import ImgBox from "@/components/ImgBox";
import LFooter from "@/components/LFooter";

export default {
    name: "driver",
    components:{
        LHeader,ImgBox,LFooter
    },

}
</script>

<style scoped>

</style>